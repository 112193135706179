/* import __COLOCATED_TEMPLATE__ from './suggestion-topic-cell.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class SuggestionTopicCell extends Component<Args> {
  get suggestionTopic() {
    return this.args.contentWrapper.contentReviewSuggestionMetadata?.topic;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::SuggestionTopicCell': typeof SuggestionTopicCell;
  }
}
