/* import __COLOCATED_TEMPLATE__ from './impact-cell.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';

interface Args {
  contentWrapper: ContentWrapper;
}

const NUMBER_OF_WEEKS = 4;

export default class ImpactCell extends Component<Args> {
  get impact() {
    // Impacted conversation size is currently based on 1 week so we multiply by 4 to get monthly impact
    // This is temporary and will be updated with https://github.com/intercom/intercom/issues/382622
    return this.args.contentWrapper.contentReviewSuggestionMetadata?.impacted_conversation_size
      ? this.args.contentWrapper.contentReviewSuggestionMetadata.impacted_conversation_size *
          NUMBER_OF_WEEKS
      : undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::ImpactCell': typeof ImpactCell;
  }
}
