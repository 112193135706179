/* import __COLOCATED_TEMPLATE__ from './content-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { exceededElasticSearchLimit } from 'embercom/helpers/matching-system/state-change';

export default class ContentEditor extends Component {
  @service contentEditorService;

  get ruleset() {
    return this.args.ruleset;
  }

  get displayESLimitBanner() {
    return exceededElasticSearchLimit(
      this.contentEditorService.ruleset?.currentVersion?.content?.lastStateChangeReason,
    );
  }

  get entityName() {
    return this.contentEditorService.configRulesetLink.humanReadableObjectName;
  }

  @action
  onRulesetChange() {
    if (this.contentEditorService.ruleset.id !== this.args.ruleset.id) {
      // the ruleset passed as an argument will change when a ruleset is duplicated
      this.contentEditorService.unregister(this.contentEditorService.ruleset);
      this.registerRuleset();
    }
  }

  @action
  duplicateRuleset(type = null) {
    this.contentEditorService.duplicateRuleset.perform(type);
  }

  @action
  unregisterRuleset() {
    if (
      this.contentEditorService.ruleset &&
      this.args.ruleset &&
      this.contentEditorService.ruleset.id === this.args.ruleset.id
    ) {
      this.contentEditorService.unregister(this.args.ruleset);
    }
  }

  @action
  registerRuleset() {
    // When duplicating, we can sometimes attempt to register the new ruleset before the old one has been unregistered.
    // Seems to happen mainly in tests. See https://github.com/intercom/intercom/issues/175143
    if (this.contentEditorService.ruleset) {
      this.contentEditorService.unregister(this.contentEditorService.ruleset);
    }
    this.contentEditorService.register({
      ruleset: this.args.ruleset,
      rulesetLink: this.args.rulesetLink,
      filter: this.args.filter,
      mode: this.args.mode,
      view: this.args.view,
      returnPath: this.args.returnPath,
      customActivate: this.args.customActivate,
      onChangeFilter: this.args.onChangeFilter,
      onChangeMode: this.args.onChangeMode,
      onChangeView: this.args.onChangeView,
      onChangeActiveRulesetLink: this.args.onChangeActiveRulesetLink,
      onActivate: this.args.onActivate,
      onDeactivate: this.args.onDeactivate,
    });
  }
}
