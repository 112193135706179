/* import __COLOCATED_TEMPLATE__ from './custom-bots-heading.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import ENV from 'embercom/config/environment';
import type IntlService from 'embercom/services/intl';
import { AI_AGENT_EMAIL_TEMPLATE_ID } from 'embercom/lib/operator/resolution-bot/constants';
import { type Action } from 'embercom/components/common/hero-banner';

interface Args {
  finLiveOverEmail: boolean | undefined;
  launchModal: () => void;
  workflowCount: number;
}

export default class CustomBotsHeading extends Component<Args> {
  @service intercomEventService: any;
  @service declare appService: any;
  @service declare intl: IntlService;

  _openUrlInSeparateTabWhenNotInTest(url: string) {
    // Prevent links from being opened in tests so that we don't skew help centre metrics
    if (ENV.environment === 'test') {
      return;
    }
    safeWindowOpen(url, '_blank');
  }

  get newHeroBannerActions(): Action[] {
    return [
      {
        type: 'link',
        label: 'operator.custom-bot.header.new-banner.actions.article-workflows-explained',
        icon: 'article',
        analyticsId: 'workflows-explained-article',
        onClick: () => {
          window.Intercom('showArticle', 7836459); // https://www.intercom.com/help/en/articles/7836459-workflows-explained
        },
      },
      {
        type: 'link',
        label:
          'operator.custom-bot.header.new-banner.actions.article-guide-leveraging-ai-and-automation',
        icon: 'book',
        analyticsId: 'guide-leveraging-ai-and-automation-article',
        onClick: () => {
          window.Intercom('showArticle', 9638929); // https://www.intercom.com/help/en/articles/9638929-get-the-best-from-ai-and-automation
        },
      },
      {
        type: 'link',
        label: 'operator.custom-bot.header.new-banner.actions.video-introduction-to-workflows',
        icon: 'video',
        analyticsId: 'introduction-to-workflows-video',
        onClick: () => {
          this._openUrlInSeparateTabWhenNotInTest(
            'https://www.youtube.com/watch?v=vpzp09O6QsM&list=PLlCIldMZCaFpsESpzEyIqKzZ_K7oZYft5',
          );
        },
      },
    ];
  }

  get app() {
    return this.appService.app;
  }

  get aiAgentEmailTemplateId() {
    return AI_AGENT_EMAIL_TEMPLATE_ID;
  }

  get bannerDismissalKey() {
    return this.args.workflowCount >= 4 ? 'discovery-banner-workflows-overview' : undefined;
  }

  @action showWorkflowsBuilderArticle() {
    window.Intercom('showArticle', 6611595); // https://www.intercom.com/help/en/articles/6611595-using-the-workflows-builder
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'using_workflows_builder_help_link',
      context: 'first_use_onboarding_v2.discovery_banner',
      place: 'custom-bots.custom-bots-heading',
      section: 'discovery_banner',
    });
  }

  @action
  openHowBotsWorkDoc() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'how_to_use_doc',
    });
  }

  @action
  openStepByStepGuidesDoc() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_more_doc',
    });
  }

  @action
  openLinkingBotsDoc() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'linking_bots_doc',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CustomBots::CustomBotsHeading': typeof CustomBotsHeading;
  }
}
