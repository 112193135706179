/* import __COLOCATED_TEMPLATE__ from './suggestion-type-cell.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  contentWrapper: ContentWrapper;
}

export default class SuggestionTypeCell extends Component<Args> {
  @service declare intl: IntlService;

  get suggestionType() {
    let contentReviewType = this.args.contentWrapper.contentReviewType;
    if (contentReviewType === ReviewType.CREATE_CONTENT) {
      return this.intl.t('ai-content-library.content-reviews.list.cells.suggestion-type.add');
    }
    if (contentReviewType === ReviewType.EDIT_CONTENT) {
      return this.intl.t('ai-content-library.content-reviews.list.cells.suggestion-type.edit');
    }
    if (contentReviewType === ReviewType.DELETE_CONTENT) {
      return this.intl.t('ai-content-library.content-reviews.list.cells.suggestion-type.delete');
    }
    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::List::Cells::SuggestionTypeCell': typeof SuggestionTypeCell;
  }
}
