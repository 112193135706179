/* import __COLOCATED_TEMPLATE__ from './schedule-or-set-live-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import {
  objectFeatures,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import type Router from '@ember/routing/router-service';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
import type IntlService from 'embercom/services/intl';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  preActivationCheck?: $TSFixMe;
  postActivationCheck?: $TSFixMe;
}

interface Signature {
  Args: Args;
}

export default class ScheduleOrSetLiveButton extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @service declare router: Router;
  @service declare finOptInService: FinOptInService;
  @service declare store: $TSFixMe;
  @service declare intl: IntlService;

  get validationTooltipContent() {
    if (this.lacksTasksSpaceOnlyForUsers) {
      return this.intl.t('outbound.checklists.validations.messenger-settings-update-required', {
        audience: 'Users',
        userType: 'Users',
      });
    }
    if (this.lacksTasksSpaceOnlyForVisitors) {
      return this.intl.t('outbound.checklists.validations.messenger-settings-update-required', {
        audience: 'Visitors or Leads',
        userType: 'Visitors',
      });
    }
    if (this.lacksTasksSpaceForUsersOrVisitors) {
      if (this.hasTasksSpaceEnabledForUsers || this.hasTasksSpaceEnabledForVisitors) {
        return this.intl.t(
          'outbound.checklists.validations.messenger-settings-update-required-for-visitors-and-users',
        );
      }
      return this.intl.t(
        'outbound.checklists.validations.messenger-upgrade-and-settings-update-required',
      );
    }
    if (this.disableSetLiveForSms) {
      if (this.isSendingBlockedForSms) {
        return this.intl.t('outbound.sms.validations.sending-blocked', {
          objectName: this.contentEditorService.configRulesetLink.humanReadableObjectName,
        });
      }
      return this.intl.t('outbound.sms.validations.activate-country', {
        objectName: this.contentEditorService.configRulesetLink.humanReadableObjectName,
      });
    }
    if (this.disableSetLiveForFin) {
      return this.intl.t('ai-agent.profiles.fin-trial-ended');
    }
    if (!this.contentEditorService.ruleset.audienceValid) {
      return `Finish creating your audience rules before setting your ${this.contentEditorService.configRulesetLink.humanReadableObjectName} live.`;
    }
    if (this.contentEditorService.ruleset.scheduledActivation) {
      return `Finish creating your ${this.contentEditorService.configRulesetLink.humanReadableObjectName} before scheduling it.`;
    } else if (!this.contentEditorService.ruleset.isLive) {
      return `Finish creating your ${this.contentEditorService.configRulesetLink.humanReadableObjectName} before setting it live.`;
    }

    return '';
  }

  get canSetLive() {
    return (
      this.contentEditorService.get('ruleset.validations.isValid') &&
      !this.contentEditorService.isUploadingFile &&
      !this.disableSetLiveForSms &&
      !this.disableSetLiveForFin &&
      !this.lacksRelevantMessengerSettings
    );
  }

  get requiredFeature() {
    return this.contentEditorService?.editorConfiguration?.requiredFeature;
  }

  get paywallHoverParams() {
    return this.contentEditorService?.editorConfiguration?.paywallHoverParams;
  }

  get disableFeatureHighlighting() {
    return this.contentEditorService?.activeRulesetLink?.objectType === objectTypes.tooltipGroup;
  }

  // disable setting SMS messages live when the workspace doesn't have an activated country
  // uses the implicit predicates to check whether there are any activated countries or not
  // The button should only be disabled when the workspace isn't paywalled and doesn't have any activated countries
  get disableSetLiveForSms() {
    if (this.isSendingBlockedForSms) {
      return true;
    }
    return (
      !this.contentEditorService.hasActivatedCountriesForSms() && !this.paywallOverrideForSmsBeta
    );
  }

  get isSendingBlockedForSms() {
    return (
      this.requiredFeature === objectFeatures[objectTypes.sms] &&
      (this.contentEditorService?.activeRulesetLink?.object.isSendingBlocked ||
        !this.contentEditorService?.activeRulesetLink?.object.hasRemainingUsage)
    );
  }

  get disableSetLiveForFin() {
    return (
      this.contentEditorService?.activeRulesetLink?.objectType ===
        objectTypes.resolutionBotBehavior &&
      this.appService.app.aiAnswersState === 'trial_expired' &&
      !this.appService.app.hasAnswerBot
    );
  }

  get paywallOverride() {
    return this.paywallOverrideForSmsBeta;
  }

  get paywallOverrideForSmsBeta() {
    if (this.requiredFeature !== objectFeatures[objectTypes.sms]) {
      return;
    }
    return (
      this.appService.app.canUseSMS &&
      !this.appService.app.canUseSmsBeta &&
      !this.appService.app?.canUseFeature('sms')
    );
  }

  get lacksRelevantMessengerSettings() {
    return (
      this.lacksTasksSpaceOnlyForUsers ||
      this.lacksTasksSpaceOnlyForVisitors ||
      this.lacksTasksSpaceForUsersOrVisitors
    );
  }

  get lacksTasksSpaceOnlyForUsers() {
    if (!this.rulesetIsForChecklist) {
      return false;
    }

    return (
      this.contentEditorService?.ruleset?.userType === 'user' && !this.hasTasksSpaceEnabledForUsers
    );
  }

  get lacksTasksSpaceOnlyForVisitors() {
    if (!this.rulesetIsForChecklist) {
      return false;
    }

    let checklistIsForVisitors = false;
    switch (this.contentEditorService?.ruleset?.userType) {
      case 'lead':
        checklistIsForVisitors = true;
        break;
      case 'visitor':
        checklistIsForVisitors = true;
        break;
      case 'non_user': // leads + visitors
        checklistIsForVisitors = true;
        break;
      default:
        checklistIsForVisitors = false;
    }

    return checklistIsForVisitors && !this.hasTasksSpaceEnabledForVisitors;
  }

  get lacksTasksSpaceForUsersOrVisitors() {
    if (!this.rulesetIsForChecklist) {
      return false;
    }

    let checklistIsForVisitorsAndUsers = false;
    switch (this.contentEditorService?.ruleset?.userType) {
      case 'non_visitor': // users + leads
        checklistIsForVisitorsAndUsers = true;
        break;
      case 'non_lead': // users + visitors
        checklistIsForVisitorsAndUsers = true;
        break;
      case 'everyone':
        checklistIsForVisitorsAndUsers = true;
        break;
      default:
        checklistIsForVisitorsAndUsers = false;
    }

    return (
      checklistIsForVisitorsAndUsers &&
      (!this.hasTasksSpaceEnabledForUsers || !this.hasTasksSpaceEnabledForVisitors)
    );
  }

  get hasTasksSpaceEnabledForUsers() {
    return Boolean(this.messengerSettings?.spaces?.spaceEnabledForUsers('isTasks'));
  }

  get hasTasksSpaceEnabledForVisitors() {
    return Boolean(this.messengerSettings?.spaces?.spaceEnabledForVisitors('isTasks'));
  }

  get rulesetIsForChecklist() {
    return this.contentEditorService?.activeRulesetLink?.objectType === objectTypes.checklist;
  }

  get messengerSettings() {
    return this.store.peekRecord('messenger-settings/all', this.appService.app.id);
  }

  @action
  async scheduleRuleset() {
    if (this.args.preActivationCheck) {
      this.args.preActivationCheck(this.contentEditorService.scheduleRuleset);
      return;
    }
    await this.contentEditorService.scheduleRuleset.perform();
  }

  @action
  async setLive() {
    if (this.args.preActivationCheck) {
      this.args.preActivationCheck(this.saveAndActivateRuleset);
    } else {
      await taskFor(this.saveAndActivateRuleset).perform();
    }
  }

  @task({ drop: true })
  *saveAndActivateRuleset(): TaskGenerator<void> {
    let result = yield this.contentEditorService.saveAndActivateRuleset.perform();

    if (result?.success) {
      if (this.args.postActivationCheck) {
        this.args.postActivationCheck();
      }
    }
  }

  @action
  async showSurveyOnboardingVideo() {
    return this.router.transitionTo({
      queryParams: { onboardingVideoDisplayed: true },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::ScheduleOrSetLiveButton': typeof ScheduleOrSetLiveButton;
    'content-editor/headers/schedule-or-set-live-button': typeof ScheduleOrSetLiveButton;
  }
}
