/* import __COLOCATED_TEMPLATE__ from './ai-content-library.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';

import { task } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { action } from '@ember/object';
import { EntityType } from 'embercom/models/data/entity-types';
import type ContentImportService from 'embercom/services/content-import-service';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { statisticKeys, statisticTypes } from 'embercom/models/data/outbound/constants';
import {
  ZENDESK_ARTICLE_SYNC_CARD,
  type Predicate,
} from 'embercom/lib/ai-content-library/constants';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { get } from 'embercom/lib/ajax';
import { isEmpty } from '@ember/utils';
import type FileSource from 'embercom/models/content-service/file-source';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type Store from '@ember-data/store';
import {
  DEFAULT_COLUMNS,
  DEFAULT_SELECTABLE_COLUMNS,
  type ColumnData,
  AI_COLUMN_DATA,
} from 'embercom/lib/ai-content-library/constants';
import {
  TEXT_CONTENT,
  FILE_CONTENT,
  EXTERNAL_CONTENT,
} from 'embercom/lib/ai-content-library/constants';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  mode?: string;
  title?: string;
  columns?: TableColumn[];
  selectableColumns?: ColumnData[];
  listApi: AiContentLibraryApi;
  searchTerm?: string;
  setSearchTerm: (searchTerm?: string) => void;
  state: string | null;
  setState: (state: string | null) => void;
  locale: string | null;
  setLocale: (locale: string | null) => void;
  createdBy?: string;
  setCreatedBy: (createdBy?: string) => void;
  setSelectedObjectTypes: (selectedObjectTypes?: Array<EntityType>) => void;
  selectedObjectTypes: Array<EntityType>;
  setSelectedHelpCenterIds: (selectedHelpCenterIds?: Array<string>) => void;
  selectedHelpCenterIds: Array<string>;
  selectedSource: string | null;
  setSelectedSource: (source: string | null) => void;
  selectedContent: { contentType: EntityType; contentId: number };
  setSelectedContent: (content: { contentType?: EntityType; contentId?: number }) => void;
  predicates: Array<Predicate>;
  setPredicates: (predicates: Array<Predicate>) => void;
  supportedFilters?: Array<{ text: string; icon: string; value: string }>;
  resetFilters: () => void;
  showNewSnippetSideDrawer: boolean;
  sortBy?: string;
  setSortBy: (sortBy?: string) => void;
  sortDirection?: string;
  setSortDirection: (sortDirection?: string) => void;
  actionType?: string;
}

type SortDirection = 'asc' | 'desc';
const updatedAt = 'updatedAt';

export interface TableColumn {
  valuePath: string;
  type?: string;
  isDisabled?: boolean;
  labelIntlKey?: string;
  tooltipIntlKey?: string;
  label?: string;
  isVisible?: boolean;
  isSortable?: boolean;
  customHeaderComponent?: string;
}

export const AI_CONTENT_LIBRARY_COLUMNS = {
  checkbox: {
    valuePath: 'checkBox',
    type: 'checkbox',
  },
  impact: {
    valuePath: 'impact',
    labelIntlKey: 'ai-content-library.content-reviews.list.columns.impact',
  },
  suggestionType: {
    valuePath: 'suggestionType',
    labelIntlKey: 'ai-content-library.content-reviews.list.columns.suggestion-type',
  },
  title: {
    valuePath: 'title',
    labelIntlKey: 'ai-content-library.list.columns.title',
    isDisabled: true,
  },
  suggestionTopic: {
    valuePath: 'suggestionTopic',
    labelIntlKey: 'ai-content-library.content-reviews.list.columns.suggestion-topic',
  },
  state: {
    valuePath: 'state',
    labelIntlKey: 'ai-content-library.list.columns.state',
  },
  locale: {
    valuePath: 'locale',
    labelIntlKey: 'ai-content-library.list.columns.locale',
  },
  contentType: {
    valuePath: 'contentType',
    labelIntlKey: 'ai-content-library.list.columns.content-type',
  },
  segments: {
    valuePath: 'segments',
    labelIntlKey: 'ai-content-library.list.columns.segments',
  },
  uses: {
    valuePath: 'uses',
    labelIntlKey: 'ai-content-library.list.columns.uses',
    tooltipIntlKey: 'ai-content-library.list.tooltips.uses',
  },
  helpCenter: {
    valuePath: 'helpCenter',
    labelIntlKey: 'ai-content-library.list.columns.help-center',
  },
  resolutions: {
    valuePath: 'resolutions',
    labelIntlKey: 'ai-content-library.list.columns.resolutions',
    tooltipIntlKey: 'ai-content-library.list.tooltips.resolutions',
  },
  size: {
    valuePath: 'contentBytesize',
    labelIntlKey: 'ai-content-library.list.columns.content-bytesize',
    tooltipIntlKey: 'ai-content-library.list.tooltips.content-bytesize',
    isSortable: true,
  },
  lastUpdatedAt: {
    valuePath: 'lastUpdatedAt',
    labelIntlKey: 'ai-content-library.list.columns.last-updated-at',
    isSortable: true,
  },
};

const BULK_UPDATE_MAX = 10_000;
const MAX_BULK_FIN_STATE_UPDATEABLE = 100;
const MAX_BULK_SUGGESTION_REVIEWABLE = 50;

export default class ContentServiceAiContentLibrary extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare intercomEventService: any;
  @service declare attributeService: any;
  @service declare helpCenterService: any;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare permissionsService: any;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  @tracked searchTerm?: string;
  @tracked selectedObjectTypes?: Array<EntityType>;
  @tracked selectedHelpCenterIds?: Array<string>;
  @tracked state: string | null;
  @tracked locale: string | null;
  @tracked createdBy?: string;
  @tracked selectedContentWrapper?: ContentWrapper;
  @tracked selectedSource: string | null;
  @tracked predicates: Array<Predicate>;
  @tracked sortBy: string | null;
  @tracked sortDirection: string | null;
  @tracked columns = this.args.columns ?? this.defaultColumns;
  @tracked selectableColumns = this.args.selectableColumns ?? DEFAULT_SELECTABLE_COLUMNS;

  @tracked permittedLocales: string[] = [];

  @tracked showSourceSettings = false;
  @tracked showFinContentModal = false;
  @tracked showUrlSourceModal = false;
  @tracked showFileUploadModal = false;
  @tracked showTargetingSettings = false;
  @tracked showContentSnippetSideDrawer = false;
  @tracked showZendeskArticleSyncModal = false;
  @tracked contentSnippet: any;
  @tracked activeFilter?: string;
  @tracked filtersApplied: string[] = [];

  @tracked contentLibrarySummary: {
    [key: number]: {
      entity_type: number;
      total_count: number;
      used_by_fin_count: number;
      additional_data: any;
    };
  } = {};
  @tracked reloadSummaryRequired = false;
  @tracked reloadPageRequired = false;

  statisticKeys = statisticKeys;
  statisticTypes = statisticTypes;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.searchTerm = args.searchTerm;
    this.selectedObjectTypes = args.selectedObjectTypes;
    this.selectedHelpCenterIds = args.selectedHelpCenterIds;
    this.state = args.state ?? null;
    this.createdBy = args.createdBy;
    this.locale = args.locale ?? null;
    this.selectedSource = args.selectedSource ?? null;
    this.predicates = args.predicates;
    this.sortBy = args.sortBy ?? null;
    this.sortDirection = args.sortDirection ?? null;
    this.contentImportService.subscribeToContentIngestionJobStatusUpdates();

    this.contentImportService.fetchContentImportSources();
    this.contentImportService.fetchFileSources();

    this.contentImportService.subscribeToFileSourceRunStatusUpdates();
    this.contentImportService.fileSourceCompletionCallback = this.reloadPageAndSummary.bind(this);
    // Where the segments are not pre-loaded in the route containing this component, load them here
    // so that the list can render the names of segments correctly.
    if (!this.aiContentSegmentsService.hasLoadedSegments) {
      this.aiContentSegmentsService.loadSegments();
    }

    this.helpCenterService.maybeFetchSite();

    if (this.args.showNewSnippetSideDrawer) {
      this.openContentSnippetSideDrawer();
    }

    if (this.isReviewMode && this.args.listApi.contentWrappers.length > 0) {
      if (args.actionType && args.actionType === 'open_content_modal') {
        this.setSelectedContentWrapper(this.firstContentWrapper);
      }
    }

    taskFor(this.initialisePermittedLocales).perform();
    if (this.router.currentRoute?.queryParams) {
      if (this.router.currentRoute.queryParams.isStandalone) {
        this.showFinContentModal =
          this.router.currentRoute.queryParams.showFinContentModal === 'true';
      }
    }
  }

  get sources(): ContentImportSource[] {
    return this.contentImportService.contentImportSources ?? [];
  }

  get fileSources(): FileSource[] {
    return this.contentImportService.fileSources ?? [];
  }

  get firstContentWrapper(): ContentWrapper {
    return this.args.listApi.contentWrappers[0];
  }

  get helpCenterSites(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  get finConsentUrl(): string {
    return '/a/apps/_/automation/fin-ai-agent/setup';
  }

  get templates() {
    if (this.appService.app.canUseStandalone) {
      if (this.appService.app.hasStandalonePlatform('salesforce')) {
        return [EXTERNAL_CONTENT, FILE_CONTENT, TEXT_CONTENT, ZENDESK_ARTICLE_SYNC_CARD];
      }
      if (this.appService.app.hasStandalonePlatform('zendesk')) {
        return [ZENDESK_ARTICLE_SYNC_CARD, EXTERNAL_CONTENT, FILE_CONTENT, TEXT_CONTENT];
      }
    }
    return [TEXT_CONTENT, FILE_CONTENT, EXTERNAL_CONTENT, ZENDESK_ARTICLE_SYNC_CARD];
  }

  get supportedFilters(): Array<{ text: string; icon: string; value: string }> {
    if (this.args.supportedFilters) {
      return this.args.supportedFilters;
    }

    return [
      {
        text: this.intl.t('ai-content-library.filters.state'),
        icon: 'active',
        value: 'state',
      },
      {
        text: this.intl.t('ai-content-library.filters.date'),
        icon: 'calendar',
        value: 'date',
      },
      {
        text: this.intl.t('ai-content-library.filters.locale'),
        icon: 'globe',
        value: 'locale',
      },
    ];
  }

  get selectableFilters(): Array<{ items: Array<{ text: string; icon: string; value: string }> }> {
    let items = this.supportedFilters;

    return [
      {
        items: items.reject(
          (item) => this.filtersApplied.includes(item.value) || this.activeFilter === item.value,
        ),
      },
    ];
  }

  get supportedDateFilters() {
    return this.attributeService._finContentLibraryDateFilterAttributes.map((data: any) =>
      this.store.createRecord('attribute', data),
    );
  }

  get hasAppliedFilter(): boolean {
    return (
      !isEmpty(this.searchTerm) ||
      !isEmpty(this.selectedObjectTypes) ||
      !isEmpty(this.selectedHelpCenterIds) ||
      !isEmpty(this.state) ||
      !isEmpty(this.locale) ||
      !isEmpty(this.selectedSource) ||
      !isEmpty(this.predicates) ||
      !isEmpty(this.createdBy)
    );
  }

  get selectedCount(): number {
    if (this.isBulkSelection) {
      return this.args.listApi.totalCount - this.unselectedWrappers.length;
    } else {
      return this.selectedWrappers.length;
    }
  }

  get maxUpdateableContents(): number {
    return BULK_UPDATE_MAX;
  }

  get maxBulkFinStateUpdateable(): number {
    return MAX_BULK_FIN_STATE_UPDATEABLE;
  }

  get maxBulkSuggestionReviewable(): number {
    return MAX_BULK_SUGGESTION_REVIEWABLE;
  }

  get isDefaultMode(): boolean {
    return this.args.mode !== 'reviews';
  }

  get isReviewMode(): boolean {
    return this.args.mode === 'reviews';
  }

  get isReviewModeAndPageLoaded(): boolean {
    return this.isReviewMode && !this.args.listApi.isLoadingPage;
  }

  get selectedContentIsAction(): boolean {
    return this.args.selectedContent.contentType === EntityType.WorkflowConnectorAction;
  }

  @action addFilter(filterType: string) {
    this.activeFilter = filterType;
    this.filtersApplied = [...this.filtersApplied, filterType];
    this.trackAnalyticsEvent('clicked', 'add_filter_button', { filter_type: filterType });
  }

  @action resetFilters() {
    this.filtersApplied.clear();
    this.activeFilter = undefined;
    this.searchTerm = undefined;
    this.selectedObjectTypes = undefined;
    this.selectedHelpCenterIds = undefined;
    this.state = null;
    this.locale = null;
    this.selectedSource = null;
    this.predicates = [];
    this.createdBy = undefined;
    this.args.resetFilters();

    this.args.listApi.reset();
    taskFor(this.onLoadMore).perform();
  }

  @action removeFilter(filterType: string) {
    this.filtersApplied = this.filtersApplied.reject((filter) => filter === filterType);
    this.activeFilter = undefined;
  }

  @tracked selectedWrappers: Array<ContentWrapper> = [];
  @tracked unselectedWrappers: Array<ContentWrapper> = [];
  @tracked isBulkSelection = false;

  @action onBulkSelect() {
    this.isBulkSelection = !this.isBulkSelection;
    this.selectedWrappers = this.isBulkSelection ? [...this.args.listApi.contentWrappers] : [];
    this.unselectedWrappers = [];
  }

  @action toggleWrapperSelection(contentWrapper: ContentWrapper): void {
    if (this.selectedWrappers.includes(contentWrapper)) {
      this.selectedWrappers.removeObject(contentWrapper);
      this.isBulkSelection ? this.unselectedWrappers.pushObject(contentWrapper) : null;
    } else {
      this.selectedWrappers.pushObject(contentWrapper);
      this.isBulkSelection ? this.unselectedWrappers.removeObject(contentWrapper) : null;
    }
  }

  @action toggleTargetingDrawer(state = false): void {
    this.showTargetingSettings = state;
    this.trackAnalyticsEvent('clicked', 'manage_targeting_button');
  }

  @action toggleSourceConfigurationDrawer(state = false): void {
    this.showSourceSettings = state;
    this.trackAnalyticsEvent('clicked', 'manage_source_button');
  }

  @action setSelectedContentWrapper(contentWrapper: ContentWrapper): void {
    this.selectedContentWrapper = contentWrapper;
    let contentId = Number(contentWrapper.contents.firstObject?.contentId);
    let contentType = contentWrapper.contents.firstObject?.contentType as EntityType;
    if (contentType === EntityType.WorkflowConnectorAction) {
      let url = this.router.urlFor(
        'apps.app.settings.app-settings.custom-actions.custom-action',
        contentId,
      );
      safeWindowOpen(url, '_blank');
    }

    this.args.setSelectedContent({
      contentType,
      contentId,
    });
  }

  @action closeContentSideDrawer(): void {
    this.args.setSelectedContent({
      contentType: undefined,
      contentId: undefined,
    });
    this.selectedContentWrapper = undefined;
    this.reloadSummary();
    this.doPageReload();
  }

  @action reloadPageAndSummary(): void {
    this.setReloadSummaryRequired();
    this.setReloadPageRequired();
    this.reloadSummary();
    this.doPageReload();
  }

  @action reloadPageAfterSourceUpdate(): void {
    this.setReloadPageRequired();
    this.doPageReload();
  }

  @action reloadPageAndSummaryAfterBulkAction(): void {
    this.reloadPageAndSummary();
    this.selectedWrappers = [];
  }

  @task
  *fetchContentReviewSummary(): TaskGenerator<void> {
    yield this.contentImportService.fetchContentReviewSummary();
  }

  @task
  *initialisePermittedLocales(): TaskGenerator<void> {
    let languageSettings = yield this.store.findRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );
    let nonDefaultPermittedLocales = languageSettings.supportedLocales
      .filter((locale: any) => locale.isPermitted)
      .map((locale: any) => locale.localeId);
    this.permittedLocales = [languageSettings.defaultLocale, ...nonDefaultPermittedLocales];
  }

  @task *paginate(): TaskGenerator<void> {
    if (!this.args.listApi.isLoadingPage) {
      yield this.args.listApi.loadPage();
    }
  }

  @task *onLoadMore(): TaskGenerator<void> {
    yield this.args.listApi.loadPage();
  }

  @task *searchByTerm(filterItem: { name: string }): TaskGenerator<void> {
    this.searchTerm = filterItem.name;
    this.args.setSearchTerm(this.searchTerm);
    yield this.args.listApi.search(filterItem.name);
    this.trackSearchEvent();
  }
  @task({ restartable: true })
  *searchByObjectTypes(objectTypes?: Array<EntityType>): TaskGenerator<void> {
    this.selectedObjectTypes = objectTypes;
    this.args.setSelectedObjectTypes(this.selectedObjectTypes);
    yield this.args.listApi.searchByObjectTypes(objectTypes);
    this.trackSearchEvent();
  }

  @task *searchByHelpCenterIds(helpCenterIds?: Array<string>): TaskGenerator<void> {
    this.selectedHelpCenterIds = helpCenterIds;
    this.args.setSelectedHelpCenterIds(this.selectedHelpCenterIds);
    yield this.args.listApi.searchByHelpCenterIds(helpCenterIds);
    this.trackSearchEvent();
  }

  @task *searchByState(state: string | null): TaskGenerator<void> {
    this.state = state;
    this.args.setState(this.state);
    yield this.args.listApi.searchByState(state ?? undefined);
    this.trackSearchEvent();
  }

  @task *searchByLocale(locale: string | null): TaskGenerator<void> {
    this.locale = locale;
    this.args.setLocale(this.locale);
    yield this.args.listApi.searchByLocale(locale ?? undefined);
    this.trackSearchEvent();
  }

  @task *searchByCreatedBy(createdBy?: string): TaskGenerator<void> {
    this.createdBy = createdBy;
    this.args.setCreatedBy(this.createdBy);
    yield this.args.listApi.searchByCreatedBy(createdBy ?? undefined);
    this.trackSearchEvent();
  }

  @task *searchBySource(sourceId: string | null): TaskGenerator<void> {
    this.selectedSource = sourceId;
    this.args.setSelectedSource(this.selectedSource);
    yield this.args.listApi.searchBySource(sourceId ?? undefined);
    this.trackSearchEvent();
  }

  @task *searchByDatePredicates(_field: string, predicates: Array<Predicate>): TaskGenerator<void> {
    this.predicates = predicates;
    this.args.setPredicates(this.predicates);
    yield this.args.listApi.searchByDatePredicates(this.predicates ?? []);
    this.trackSearchEvent();
  }

  @task *resetSearchByTerm(): TaskGenerator<void> {
    this.searchTerm = undefined;
    this.args.setSearchTerm(this.searchTerm);
    yield this.args.listApi.search(this.searchTerm);
  }

  @task *resetSearch(): TaskGenerator<void> {
    this.searchTerm = undefined;
    this.args.setSearchTerm(this.searchTerm);
    this.selectedObjectTypes = undefined;
    this.args.setSelectedObjectTypes(this.selectedObjectTypes);
    this.selectedHelpCenterIds = undefined;
    this.args.setSelectedHelpCenterIds(this.selectedHelpCenterIds);
    this.state = null;
    this.args.setState(this.state);
    this.args.setLocale(this.locale);
    this.selectedSource = null;
    this.args.setSelectedSource(this.selectedSource);
    this.createdBy = undefined;
    this.args.setCreatedBy(this.createdBy);
    this.predicates = [];
    this.args.setPredicates(this.predicates);
    this.args.listApi.reset();
    yield this.args.listApi.loadPage();
  }

  @task *reloadPage(): TaskGenerator<void> {
    yield this.args.listApi.searchByDatePredicates(this.predicates ?? []);
    yield this.args.listApi.searchByObjectTypes(this.selectedObjectTypes);
    yield this.args.listApi.loadPage();
  }

  @task *sortUpdate(sortBy: string, sortDirection: SortDirection): TaskGenerator<void> {
    this.sortBy = sortBy;
    this.args.setSortBy(this.sortBy ?? undefined);
    this.sortDirection = sortDirection;
    this.args.setSortDirection(this.sortDirection ?? undefined);
    yield this.args.listApi.sortUpdate(sortBy ?? undefined, sortDirection ?? undefined);
  }

  @action toggleFinContentModal(state = false): void {
    this.showFinContentModal = state;
    this.trackAnalyticsEvent('clicked', 'new_content_button');
  }

  @action async openContentSnippetSideDrawer(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      this.showFinContentModal = false;
      return;
    }

    taskFor(this.createContentSnippet).perform();
    this.showFinContentModal = false;
    this.showContentSnippetSideDrawer = true;
    this.trackAnalyticsEvent('clicked', 'new_text_content_card');
  }

  @action async openUrlSourceModal(): Promise<void> {
    try {
      this.showFinContentModal = false;
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showUrlSourceModal = true;
      this.trackAnalyticsEvent('clicked', 'new_url_content_card');
    } catch (e) {
      return;
    }
  }

  @action async openZendeskArticleSyncModal(): Promise<void> {
    this.showFinContentModal = false;
    this.showZendeskArticleSyncModal = true;
    this.trackAnalyticsEvent('clicked', 'new_zendesk_article_sync_card');
  }

  @action async toggleOnFileUpload(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showFinContentModal = false;
      this.showFileUploadModal = true;
      this.trackAnalyticsEvent('clicked', 'new_file_content_card');
    } catch (e) {
      this.showFinContentModal = false;
      return;
    }
  }

  @action handleModalBackBtnClick(): void {
    this.showFileUploadModal = false;
    this.showUrlSourceModal = false;
    this.showFinContentModal = true;
    this.trackAnalyticsEvent('clicked', 'file_upload_back_button');
  }

  @action closeContentSnippetDrawer(): void {
    this.showContentSnippetSideDrawer = false;
    taskFor(this.reloadPage).perform();
    this.reloadSummary();
  }

  @task *createContentSnippet(): TaskGenerator<void> {
    this.contentSnippet = yield this.store.createRecord('content-service/content-snippet').save();
    this.reloadSummaryRequired = true;
  }

  @task *fetchLibrarySummary(): TaskGenerator<void> {
    this.contentLibrarySummary = yield get(
      '/ember/content_service/contents/ai_content_library_summary',
      {
        app_id: this.appService.app.id,
      },
    );
  }

  @action setReloadSummaryRequired(): void {
    this.reloadSummaryRequired = true;
  }

  @action setReloadPageRequired(): void {
    this.reloadPageRequired = true;
  }

  @action
  onSort(clickedValuePath: string): void {
    let sortBy = clickedValuePath === 'lastUpdatedAt' ? updatedAt : clickedValuePath;
    let newSortDirection: SortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortDirection = newSortDirection;
    taskFor(this.sortUpdate).perform(sortBy, newSortDirection);
  }

  @action startToApprove(): void {
    if (this.appService.app.canUseContextEditorDiffMode) {
      let content = this.firstContentWrapper.contents.firstObject!;
      this.knowledgeHubDrawerEditorService.openReviewDrawer({
        activeContentId: content.contentId,
        activeContentType: objectNames[content.contentType],
      });
    } else {
      this.setSelectedContentWrapper(this.firstContentWrapper);
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'start_to_approve',
      section: 'operator',
      context: 'fin_content_suggestions',
      place: 'fin_content_suggestions',
    });
  }

  @action loadNextContentWrapper(currentPosition: number): void {
    if (!this.isReviewMode) {
      return;
    }
    this.setSelectedContentWrapper(this.args.listApi.contentWrappers[currentPosition + 1]);
    this.args.listApi.contentWrappers.removeAt(currentPosition);
    this.reloadPageRequired = true;
  }

  @action loadContentForPosition(position: number): void {
    if (!this.isReviewMode) {
      return;
    }
    this.setSelectedContentWrapper(this.args.listApi.contentWrappers[position]);
  }

  @action removeLastContent(): void {
    if (!this.isReviewMode) {
      return;
    }
    this.args.listApi.contentWrappers.pop();
  }

  @action
  updatedSelectedTableColumns(columns: string[]) {
    columns.unshift('suggestionTopic');
    columns.unshift('title');
    columns.unshift('suggestionType');
    columns.unshift('impact');
    columns.unshift('checkBox');
    this.columns = columns.map((col) => AI_COLUMN_DATA[col]);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'changed_displayed_columns',
      object: 'all_table',
      context: 'ai_content_library',
      columns,
    });
  }

  private trackSearchEvent(): void {
    this.trackAnalyticsEvent('filter', 'search', {
      searchTerm: this.searchTerm,
      selectedObjectTypes: this.selectedObjectTypes,
      state: this.state,
      sourceId: this.selectedSource,
      locale: this.locale,
    });
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'ai_content_library',
      place: 'ai_content_library',
      ...metadata,
    });
  }

  private reloadSummary(): void {
    if (this.reloadSummaryRequired) {
      taskFor(this.fetchLibrarySummary).perform();
      taskFor(this.fetchContentReviewSummary).perform();
      this.reloadSummaryRequired = false;
    }
  }

  private doPageReload(): void {
    if (this.reloadPageRequired) {
      taskFor(this.reloadPage).perform();
      this.reloadPageRequired = false;
    }
  }

  get isLoading(): boolean {
    return taskFor(this.onLoadMore).isRunning;
  }

  get isReloading(): boolean {
    return taskFor(this.reloadPage).isRunning;
  }

  // TODO: Move this elsewhere once the create and view content snippet side drawers are unified
  get isLoadingContentSnippet(): boolean {
    return taskFor(this.createContentSnippet).isRunning;
  }

  get externalContentSelected(): boolean {
    return this.selectedObjectTypes?.includes(EntityType.ExternalContent) ?? false;
  }

  get articleContentSelected(): boolean {
    return this.selectedObjectTypes?.includes(EntityType.ArticleContent) ?? false;
  }

  get showHelpCenterFilter(): boolean {
    return this.articleContentSelected && this.helpCenterSites?.length > 1;
  }

  get selectedColumns(): TableColumn[] {
    let columns = this.columns;

    return this._filteredColumns(columns);
  }

  get defaultColumns(): TableColumn[] {
    let columns = this.args.columns ?? DEFAULT_COLUMNS;
    return this._filteredColumns(columns);
  }

  _filteredColumns(columns: TableColumn[]) {
    if (!this.appService.app.canUseSuggestionColumns) {
      let suggestionCols = ['suggestionType', 'suggestionTopic', 'impact'];
      columns = columns.filter((column) => !suggestionCols.includes(column.valuePath));
    } else {
      columns = columns.filter((column) => column.valuePath !== 'createdBy');
    }

    return columns.map((column) => {
      return {
        ...column,
        label: column.labelIntlKey ? this.intl.t(column.labelIntlKey) : '',
        tooltip: column.tooltipIntlKey ? this.intl.t(column.tooltipIntlKey) : '',
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary': typeof ContentServiceAiContentLibrary;
  }
}
