/* import __COLOCATED_TEMPLATE__ from './ingestion-state-banner.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { Status } from 'embercom/models/data/content-service/ai/ingestion-job-statuses';
import type Router from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';

interface Args {}

export default class IngestionStateBanner extends Component<Args> {
  @service declare appService: any;
  @service declare contentImportService: any;
  @service declare router: Router;

  @tracked declare showAgressivePruneOptInModal: boolean;

  get app() {
    return this.appService.app;
  }

  get currentQuotaUsage() {
    return this.contentImportService.currentQuotaUsage;
  }

  get contentSizeAboveMaxQuota() {
    return this.contentImportService.contentSizeAboveMaxQuota;
  }

  get contentSizeApproachingMaxQuota() {
    return this.contentImportService.contentSizeApproachingMaxQuota;
  }

  get showBanner(): boolean {
    return this.contentImportService.finIngestionJobStatusBanner?.display ?? false;
  }

  get workspaceLevelIngestionInProgress(): boolean {
    return (
      this.showBanner &&
      (this.contentImportService.finIngestionJobStatusBanner?.status === Status.InProgress ||
        this.contentImportService.finIngestionJobStatusBanner?.status === Status.Pending ||
        this.contentImportService.finIngestionJobStatusBanner?.status === Status.SentToAnswerBot)
    );
  }

  get workspaceLevelIngestionCompleted(): boolean {
    return (
      this.showBanner &&
      this.contentImportService.finIngestionJobStatusBanner?.status === Status.Completed
    );
  }

  get numberOfPendingItemsToReview() {
    return this.contentImportService.aiContentReviewSummary.pending;
  }

  get showPendingSuggestionBanner() {
    return this.router.currentRouteName.match('apps.app.automation.fin-ai-agent.content');
  }

  get showAggressivePruningOptIn() {
    return !this.app.canUseApifyAgressivePruning || !this.app.canUseExternalContentReadibility;
  }

  @action onAggressivePruneClick() {
    this.showAgressivePruneOptInModal = true;
  }

  @task({ drop: true })
  *onAggressivePruneOptInClick() {
    yield post('/ember/external_content_aggressive_prune_opt_ins', {
      app_id: this.app.id,
    });

    this.app.canUseApifyAgressivePruning = true;
    this.app.canUseExternalContentReadibility = true;
    this.contentImportService.contentBytesize = 0;
    this.contentImportService.showFinIngestionJobStatusBanner();
    this.showAgressivePruneOptInModal = false;
  }

  @action hideWorkspaceLevelIngestionBanner(): void {
    this.contentImportService.hideFinIngestionJobStatusBanner();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Banners::IngestionStateBanner': typeof IngestionStateBanner;
  }
}
