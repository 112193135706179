/* import __COLOCATED_TEMPLATE__ from './edit-button.hbs'; */
/** RESPONSIBLE TEAM: team-proactive-support **/
import Component from '@glimmer/component';

interface Args {}

interface Signature {
  Args: Args;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class EditButton extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentEditor::Headers::ViewMode::EditButton': typeof EditButton;
    'content-editor/headers/view-mode/edit-button': typeof EditButton;
  }
}
